@mixin backgroundImage {

    background-color: white;
    background-image: url("/background.webp"), url("/background.jpg") ;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin: 0;
    height: 100%;
}
