@import "../../styles/flex";
@import "../../styles/colors";
@import "../../styles/backgroundImage";
@import "../../styles/dimensions";

.container {

    @include backgroundImage();
    position: relative;
    box-sizing: border-box;
    height: 100vh;
    width: 100%;

    color: white;

    .transition {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 50px;
        background: linear-gradient(transparent, $space-gray);
        z-index: 5;
    }

    .overlay {
        @include flex(row, flex-start, center);

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.65);

        .grid {
            @include flex(row, center, center);
            
            box-sizing: border-box;
            width: 60%;

            .content {
                width: 65%;

                @media (max-width: $mobile-width) {
                    width: 100%;
                    padding: 0.5em;
                }

                h1 {
                    font-size: 6rem;
                    margin: 0;
                    color: $secondary-color;
                    
                    @media (max-width: $mobile-width) {
                        font-size: 4rem;
                    }
                }

                P {
                    font-size: 2rem;
                    color: $white;
                }
            }


        }

        @media (max-width: $mobile-width) {
            @include flex(row, center, flex-start);

            .grid {
                width: 100%;
                margin: 20px;

                .content {
                    width: 100%;
                }
            }
        }
    }
}